<template>
  <div class="p-12 -mx-64 w-[100vw] h-[100vh] bg-white text-dark">
    <div class="absolute top-8 right-8 z-[60]">
      <div class="rounded-full hover:bg-dark/10 cursor-pointer p-4">
        <TimesIcon
          :fontControlled="false"
          class="w-24"
          @click="dialogs.hideDialog('checks_duty')"
        />
      </div>
    </div>
    <div v-if="step === -1" class="grid grid-cols-12 h-100%">
      <ChecksDialogsSidebarA />
      <div
        class="md:hidden col-span-12 flex flex-col size-fit justify-center place-items-center"
      >
        <div>
          <div class="flex justify-center mb-32">
            <ChecksIcon2 :fontControlled="false" class="h-192" />
          </div>
          <div class="mb-16 text-content-title text-center">Pflichtencheck</div>
          <div class="mb-24 text-content-subtitle text-center">
            Mit dem Pflichtencheck prüfen Sie in wenigen Schritten ob Sie Ihre
            Betreiberpflichten vollständig erfüllen. Am Ende erhalten Sie ein
            Ergebnis und optional die Möglichkeit einen Berater dazu zu
            kontaktieren.
          </div>
          <div class="flex justify-center">
            <KisenyaButton
              type="primary"
              label="Starte Pflichtencheck"
              @click="
                step = 1;
                stepId = 'a';
              "
            >
              <template #icon>
                <RightArrowIcon :fontControlled="false" class="w-24" />
              </template>
            </KisenyaButton>
          </div>
        </div>
      </div>
      <div class="p-24 hidden md:block md:col-span-9 relative">
        <div class="mb-16 text-content-title">Pflichtencheck</div>
        <div class="mb-24 text-content-subtitle">
          Mit dem Pflichtencheck prüfen Sie in wenigen Schritten ob Sie Ihre
          Betreiberpflichten vollständig erfüllen. Am Ende erhalten Sie ein
          Ergebnis und optional die Möglichkeit einen Berater dazu zu
          kontaktieren.
        </div>
        <div class="border-1 border-stroke-color-1 w-100% mb-64"></div>
        <div><ChecksIcon2 :fontControlled="false" class="h-[24rem]" /></div>
        <div class="flex justify-end absolute right-0 bottom-0">
          <KisenyaButton
            type="primary"
            label="Starte Pflichtencheck"
            @click="
              step = 1;
              stepId = 'a';
            "
          >
            <template #icon>
              <RightArrowIcon :fontControlled="false" class="w-24" />
            </template>
          </KisenyaButton>
        </div>
      </div>
    </div>
    <div v-else-if="step === 12" class="grid grid-cols-12 h-100%">
      <ChecksDialogsSidebarB />
      <div
        class="p-24 col-span-12 md:col-span-9 relative overflow-scroll text-center text-dark"
      >
        <div class="flex justify-center mb-24">
          <KisenyaProgress
            v-model="finalScore"
            :max-value="9"
            :min-boundary="4"
            :max-boundary="9"
            :label="`${finalScore}/9`"
          />
        </div>
        <div v-if="finalScore != 9">
          <div class="text-content-title mb-24">
            Ihr Ergebnis ist unzureichend.
          </div>
          <div class="text-content-subtitle mb-24">
            {{ finalScore }}/9 der Betreiberpflichten wurden erfüllt. Wir
            empfehlen dringend die verbleibenden {{ 9 - finalScore }} Pflichten
            zu erfüllen. <br /><br />
          </div>
          <div class="text-content-subtitle italic mb-24">
            Ihr Ergebnis wurde nun per E-Mail an Sie versendet.
          </div>
        </div>
        <div v-else>
          <div class="text-content-title mb-24">Ihr Ergebnis ist sehr gut!</div>
          <div class="text-content-subtitle mb-24">
            Alle Betreiberpflichten werden eingehalten. Drucken Sie das Ergebnis
            aus und legen Sie es in Ihrer Dokumentation ab.
          </div>
          <div class="text-content-subtitle italic mb-24">
            Ihr Ergebnis wurde nun per E-Mail an Sie versendet.
          </div>
        </div>
        <div class="flex flex-col md:flex-row justify-center gap-16 mb-32">
          <KisenyaButton
            @click="resetForm"
            type="outlined"
            label="Test erneut durchführen"
          />
        </div>
        <div class="hidden md:block">
          <div class="border-1 border-stroke-color-1 mb-32"></div>
          <div class="flex items-center gap-32">
            <div>
              <ArrowIcon :fontControlled="false" class="w-48" />
            </div>
            <div class="bg-[#DDE8F4] p-16 rounded-12">
              Sie wollen nicht jedes mal wieder alle Liftdaten<br />
              ausfüllen? Nutzen Sie jetzt unsere Software.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="grid grid-cols-12 h-100%">
      <ChecksDialogsSidebarB />
      <div
        class="p-24 col-span-12 md:col-span-9 relative overflow-scroll flex flex-col"
      >
        <KisenyaStepper
          :currentStepId="stepId"
          :steps="steps"
          @next-progress="setProgressTo(step + 1)"
          @prev-progress="setProgressTo(step - 1)"
          @change-progress="setProgressTo"
          :nextButtonLabel="
            step === 11 ? 'Pflichten checken lassen' : 'Nächster Schritt'
          "
        >
          <template #a>
            <div>
              <KisenyaFormBuilder
                :ref="(el) => (stepsRef['a'] = el as VueElementVerifiable)"
                v-model="form"
                :form="offerStepA"
              /></div
          ></template>
          <template #b>
            <div>
              <KisenyaFormBuilder
                :ref="(el) => (stepsRef['b'] = el as VueElementVerifiable)"
                v-model="form"
                :form="offerStepB"
              /></div
          ></template>
          <template #c>
            <div>
              <KisenyaFormBuilder
                :ref="(el) => (stepsRef['c'] = el as VueElementVerifiable)"
                v-model="form"
                :form="offerStepC"
              /></div
          ></template>
          <template #d>
            <div>
              <KisenyaFormBuilder
                :ref="(el) => (stepsRef['d'] = el as VueElementVerifiable)"
                v-model="form"
                :form="offerStepD"
              /></div
          ></template>
          <template #e>
            <div>
              <KisenyaFormBuilder
                :ref="(el) => (stepsRef['e'] = el as VueElementVerifiable)"
                v-model="form"
                :form="offerStepE"
              /></div
          ></template>
          <template #f>
            <div>
              <KisenyaFormBuilder
                :ref="(el) => (stepsRef['f'] = el as VueElementVerifiable)"
                v-model="form"
                :form="offerStepF"
              /></div
          ></template>
          <template #g>
            <div>
              <KisenyaFormBuilder
                :ref="(el) => (stepsRef['g'] = el as VueElementVerifiable)"
                v-model="form"
                :form="offerStepG"
              /></div
          ></template>
          <template #h>
            <div>
              <KisenyaFormBuilder
                :ref="(el) => (stepsRef['h'] = el as VueElementVerifiable)"
                v-model="form"
                :form="offerStepH"
              /></div
          ></template>
          <template #i>
            <div>
              <KisenyaFormBuilder
                :ref="(el) => (stepsRef['i'] = el as VueElementVerifiable)"
                v-model="form"
                :form="offerStepI"
              /></div
          ></template>
          <template #k>
            <div>
              <KisenyaFormBuilder
                :ref="(el) => (stepsRef['k'] = el as VueElementVerifiable)"
                v-model="form"
                :form="offerStepK"
              /></div
          ></template>
          <template #j>
            <div>
              <KisenyaFormBuilder
                :ref="(el) => (stepsRef['j'] = el as VueElementVerifiable)"
                v-model="form"
                :form="offerStepJ"
              /></div
          ></template>
        </KisenyaStepper>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import RightArrowIcon from "~/assets/icons/arrow-right-solid.svg";
import TimesIcon from "~/assets/icons/times-solid.svg";

import ChecksIcon2 from "~/assets/checks_2.svg?component";
import ArrowIcon from "~/assets/arrow_1.svg?component";

import { useDialogsStore } from "~/stores/dialogs";
import { useLoaderStore } from "~/stores/loader";

const router = useRouter();
const route = useRoute();

router.replace({ path: route.path, hash: "#Pflichtencheck" });

const supabase = useSupabaseClient();

const dialogs = useDialogsStore();
const loader = useLoaderStore();

const step = ref(-1);
const stepId = ref("");

const finalScore = ref(0);

const form = ref({
  a1: "",
  a2: "",
  a3: "",
  a4: "",
  b1: "",
  // b2: "",
  b3: "",
  b4: [] as KisenyaUploaderFile[],
  b5: [] as KisenyaUploaderFile[],
  b6: "",
  c1: "",
  d1: "",
  //  d2: "",
  d3: "",
  d4: "",
  e1: "",
  e2: "",
  e3: "",
  f1: "",
  f2: "",
  f3: "",
  f4: [] as KisenyaUploaderFile[],
  f5: "",
  g1: "",
  g2: "",
  h1: "",
  h2: "",
  h3: [] as KisenyaUploaderFile[],
  i1: "",
  i2: "",
  i3: "",
  i4: [] as KisenyaUploaderFile[],

  k1: "",

  j1: "",
  j2: "",
  j3: "",
  // j4: false,
  j5: false,
});

const resetForm = () => {
  step.value = -1;
  stepId.value = "";
  finalScore.value = 0;
  form.value = {
    a1: "",
    a2: "",
    a3: "",
    a4: "",
    b1: "",
    // b2: "",
    b3: "",
    b4: [],
    b5: [],
    b6: "",
    c1: "",
    d1: "",
    //  d2: "",
    d3: "",
    d4: "",
    e1: "",
    e2: "",
    e3: "",
    f1: "",
    f2: "",
    f3: "",
    f4: [],
    f5: "",
    g1: "",
    g2: "",
    h1: "",
    h2: "",
    h3: [],
    i1: "",
    i2: "",
    i3: "",
    i4: [],

    k1: "",

    j1: "",
    j2: "",
    j3: "",
    // j4: false,
    j5: false,
  };
};

const steps = ref<Step[]>([
  {
    id: "a",
    step: 1,
    title: "Allgmeine Informationen zu Ihrem Aufzug",
  },
  {
    id: "b",
    step: 2,
    title: "Check Gefährdungsbeurteilung",
    subtitle:
      "Unabhängig von der jährlichen „TÜV-Prüfung“ müssen Aufzüge sicher nach dem Stand der Technik betrieben werden.",
  },
  {
    id: "c",
    step: 3,
    title: "Technische Dokumentation",
  },
  {
    id: "d",
    step: 4,
    title: "Zugang zum Gebäude",
  },
  {
    id: "e",
    step: 5,
    title: "Aufzugswärter",
  },
  {
    id: "f",
    step: 6,
    title: "Wartung und Instandhaltung",
  },
  {
    id: "g",
    step: 7,
    title: "Personenbefreiung",
  },
  {
    id: "h",
    step: 8,
    title: "Notfallplan",
  },
  {
    id: "i",
    step: 9,
    title: "Wiederkehrende Prüfungen",
  },
  {
    id: "k",
    step: 10,
    title: "Cybersicherheit",
  },
  {
    id: "j",
    step: 11,
    title: "Ihre Kontaktinformationen",
  },
]);

const stepsRef = ref<{ [key: string]: VueElementVerifiable }>({});

function replaceGermanLetters(text: string): string {
  const replacements: { [key: string]: string } = {
    ä: "ae",
    ö: "oe",
    ü: "ue",
    ß: "ss",
    Ä: "Ae",
    Ö: "Oe",
    Ü: "Ue",
  };

  let result = "";
  for (const char of text) {
    result += replacements[char] || char;
  }

  return result;
}

const emailRule =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

const setProgressTo = async (target: number) => {
  const newStepId = steps.value.find(
    (stepInfo) => stepInfo.step === target,
  )?.id;

  if (target > step.value) {
    const currentStepRef = stepsRef.value[stepId.value] as VueElementVerifiable;
    const verification = currentStepRef.verify();

    if (!verification) {
      return;
    }
  }

  if (target === 12) {
    loader.showLoader();

    try {
      const formData = new FormData();

      for (const key of Object.keys(form.value) as Array<
        keyof typeof form.value
      >) {
        const value = form.value[key];

        if (
          key === "b4" ||
          key === "b5" ||
          key === "f4" ||
          key === "h3" ||
          key === "i4"
        ) {
          (value as KisenyaUploaderFile[]).forEach(
            (item: KisenyaUploaderFile) =>
              formData.append(
                key,
                item.file,
                replaceGermanLetters(item.file.name.normalize("NFC")),
              ),
          );
        } else formData.append(key, String(value));
      }

      const { error } = await supabase.functions.invoke(
        "duty-check-form-submission",
        {
          body: formData,
        },
      );

      if (error) throw new Error(error);

      const checkedQuestions = [
        ["b1"],
        ["c1"],
        ["d1", "d3"],
        ["e1", "e2", "e3"],
        ["f1", "f5"],
        ["g1", "g2"],
        ["h1", "h2"],
        ["i1", "i2"],
        ["k1"],
      ] as Array<Array<keyof typeof form.value>>;

      let score = 0;

      for (const row of checkedQuestions) {
        let scored = true;
        for (const question of row) {
          if (form.value[question] === "ja") continue;
          scored = false;
          break;
        }
        if (scored) score++;
      }

      finalScore.value = score;

      step.value = 12;
      stepId.value = "";
    } catch (error) {
      console.log(error);
    } finally {
      loader.hideLoader();
      return;
    }
  }

  if (newStepId) {
    step.value = target;
    stepId.value = newStepId;
  }
};

const offerStepA: KisenyaFormBuilderFormField[] = [
  {
    id: "a1",
    type: "input",
    args: {
      required: true,
      placeholder: "Musterstraße 12, 12345 Musterstadt",
    } as KisenyaInputProps,
    slots: {
      label: "Standort des Aufzugs",
    },
  },
  {
    id: "a2",
    type: "input",
    args: {
      required: true,
      placeholder: "123456789",
    } as KisenyaInputProps,
    slots: {
      label: "Fabriknummer",
    },
  },
  {
    id: "a3",
    type: "input",
    args: {
      required: true,
      placeholder: "Schindler, OTIS, etc...",
    } as KisenyaInputProps,
    slots: {
      label: "Hersteller",
    },
  },
  {
    id: "a4",
    type: "input",
    args: {
      required: true,
      placeholder: "2020",
    } as KisenyaInputProps,
    slots: {
      label: "Baujahr",
    },
  },
];
const offerStepB: KisenyaFormBuilderFormField[] = [
  {
    id: "b1",
    type: "radio_group",
    args: {
      required: true,
      direction: "horizontal",
      options: [
        {
          value: "ja",
          label: "ja",
        },
        {
          value: "nein",
          label: "nein",
        },
        {
          value: "überspringen",
          label: "überspringen",
        },
      ],
    } as KisenyaRadioGroupProps,
    slots: {
      label: "Liegt eine aktuelle Gefährdungsbeurteilung vor?",
    },
  },
  /*
  {
    id: "b2",
    type: "radio_group",
    args: {
      required: true,
      direction: "horizontal",
      options: [
        {
          value: "ja",
          label: "ja",
        },
        {
          value: "nein",
          label: "nein",
        },
        {
          value: "überspringen",
          label: "überspringen",
        },
      ],
    } as KisenyaRadioGroupProps,
    slots: {
      label:
        "Gibt es offene Mängel aus einer aktuellen Gefährdungsbeurteilung, die noch nicht behoben wurden?",
    },
  },
  */
  {
    id: "b3",
    type: "radio_group",
    args: {
      required: true,
      direction: "horizontal",
      options: [
        {
          value: "ja",
          label: "ja",
        },
        {
          value: "nein",
          label: "nein",
        },
        {
          value: "überspringen",
          label: "überspringen",
        },
      ],
    } as KisenyaRadioGroupProps,
    slots: {
      label:
        "Gibt es einen Maßnahmenplan mit Angeboten eines Aufzugsunternehmens zur Beseitigung der Mängel aus einer aktuellen Gefährdungsbeurteilung?",
    },
  },
  {
    id: "b4",
    type: "uploader",
    args: {} as KisenyaUploaderProps,
    slots: {
      label:
        "Laden Sie hier sofern vorhanden die aktuelle Gefährdungsbeurteilung hoch. ",
    },
  },
  {
    id: "b5",
    type: "uploader",
    args: {} as KisenyaUploaderProps,
    slots: {
      label:
        "Laden Sie hier sofern vorhanden die aktuellen Angebote (Maßnahmen) zur Beseitigung der Mängel aus der Gefährdungsbeurteilung hoch.",
    },
  },
  {
    id: "b6",
    type: "input",
    args: {
      type: "textarea",
      placeholder: "Beschreiben Sie in wenigen Worten",
    } as KisenyaInputProps,
    slots: {
      label:
        "Welche Schritte haben Sie eingeleitet, um Ihre Betreiberpflicht in Bezug auf die Gefährdungsbeurteilung zu erfüllen? Beschreiben Sie die aktuelle Situation.",
    },
  },
];

const offerStepC: KisenyaFormBuilderFormField[] = [
  {
    id: "c1",
    type: "radio_group",
    args: {
      required: true,
      direction: "horizontal",
      options: [
        {
          value: "ja",
          label: "ja",
        },
        {
          value: "nein",
          label: "nein",
        },
        {
          value: "überspringen",
          label: "überspringen",
        },
      ],
    } as KisenyaRadioGroupProps,
    slots: {
      label:
        "Liegt die vollständige technische Dokumentation (Prüfbuch) der Aufzugsanlage am Aufzug vor?",
    },
  },
];

const offerStepD: KisenyaFormBuilderFormField[] = [
  {
    id: "d1",
    type: "radio_group",
    args: {
      required: true,
      direction: "horizontal",
      options: [
        {
          value: "ja",
          label: "ja",
        },
        {
          value: "nein",
          label: "nein",
        },
        {
          value: "überspringen",
          label: "überspringen",
        },
      ],
    } as KisenyaRadioGroupProps,
    slots: {
      label: "Ist der Zugang zum Gebäude für den Wartungsdienst gewährleistet?",
    },
  },
  /*  {
    id: "d2",
    type: "input",
    args: {
      required: true,
      type: "text",
    } as KisenyaInputProps,
    slots: {
      label:
        "Wie wird der Zugang zum Gebäude für den Wartungsdienst gewährleistet?",
    },
  },
*/ {
    id: "d3",
    type: "radio_group",
    args: {
      required: true,
      direction: "horizontal",
      options: [
        {
          value: "ja",
          label: "ja",
        },
        {
          value: "nein",
          label: "nein",
        },
        {
          value: "überspringen",
          label: "überspringen",
        },
      ],
    } as KisenyaRadioGroupProps,
    slots: {
      label:
        "Ist der Zugang zum Gebäude für die wiederkehrende Prüfung gewährleistet?",
    },
  },
  {
    id: "d4",
    type: "radio_group",
    args: {
      required: true,
      direction: "horizontal",
      options: [
        {
          value: "ja",
          label: "ja",
        },
        {
          value: "nein",
          label: "nein",
        },
        {
          value: "überspringen",
          label: "überspringen",
        },
      ],
    } as KisenyaRadioGroupProps,
    slots: {
      label: "Ist der Zugang für den Notbefreiungsdienst gewährleistet?",
    },
  },
];

const offerStepE: KisenyaFormBuilderFormField[] = [
  {
    id: "e1",
    type: "radio_group",
    args: {
      required: true,
      direction: "horizontal",
      options: [
        {
          value: "ja",
          label: "ja",
        },
        {
          value: "nein",
          label: "nein",
        },
        {
          value: "überspringen",
          label: "überspringen",
        },
      ],
    } as KisenyaRadioGroupProps,
    slots: {
      label:
        "Werden regelmäßige Sichtkontrollen durch einen Aufzugswärter oder eine beauftragte Person an der Anlage durchgeführt?",
    },
  },
  {
    id: "e2",
    type: "radio_group",
    args: {
      required: true,
      direction: "horizontal",
      options: [
        {
          value: "ja",
          label: "ja",
        },
        {
          value: "nein",
          label: "nein",
        },
        {
          value: "überspringen",
          label: "überspringen",
        },
      ],
    } as KisenyaRadioGroupProps,
    slots: {
      label: "Gibt es für die Sichtkontrollen ein jeweiliges Protokoll?",
    },
  },
  {
    id: "e3",
    type: "radio_group",
    args: {
      required: true,
      direction: "horizontal",
      options: [
        {
          value: "ja",
          label: "ja",
        },
        {
          value: "nein",
          label: "nein",
        },
        {
          value: "überspringen",
          label: "überspringen",
        },
      ],
    } as KisenyaRadioGroupProps,
    slots: {
      label: "Werden die Protokolle zentral und schnell auffindbar abgelegt?",
    },
  },
];

const offerStepF: KisenyaFormBuilderFormField[] = [
  {
    id: "f1",
    type: "radio_group",
    args: {
      required: true,
      direction: "horizontal",
      options: [
        {
          value: "ja",
          label: "ja",
        },
        {
          value: "nein",
          label: "nein",
        },
        {
          value: "überspringen",
          label: "überspringen",
        },
      ],
    } as KisenyaRadioGroupProps,
    slots: {
      label: "Gibt es einen Wartungsvertrag nach DIN13015 für den Aufzug?",
    },
  },
  {
    id: "f2",
    type: "radio_group",
    args: {
      required: true,
      direction: "horizontal",
      options: [
        {
          value: "ja",
          label: "ja",
        },
        {
          value: "nein",
          label: "nein",
        },
        {
          value: "überspringen",
          label: "überspringen",
        },
      ],
    } as KisenyaRadioGroupProps,
    slots: {
      label:
        "Sind Instandhaltungsarbeiten und Verschleißteile im Wartungsvertrag grundsätzlich enthalten?",
    },
  },
  {
    id: "f3",
    type: "radio_group",
    args: {
      required: true,
      direction: "horizontal",
      options: [
        {
          value: "ja",
          label: "ja",
        },
        {
          value: "nein",
          label: "nein",
        },
        {
          value: "überspringen",
          label: "überspringen",
        },
      ],
    } as KisenyaRadioGroupProps,
    slots: {
      label:
        "Wurde der Wartungsvertrag durch einen neutralen Aufzugsberater inhaltlich und juristisch geprüft?",
    },
  },
  {
    id: "f4",
    type: "uploader",
    args: {} as KisenyaUploaderProps,
    slots: {
      label:
        "Laden Sie den bestehenden Wartungsvertrag hoch. Dieser wird für Sie fachlich und juristisch geprüft.",
    },
  },
  {
    id: "f5",
    type: "radio_group",
    args: {
      required: true,
      direction: "horizontal",
      options: [
        {
          value: "ja",
          label: "ja",
        },
        {
          value: "nein",
          label: "nein",
        },
        {
          value: "überspringen",
          label: "überspringen",
        },
      ],
    } as KisenyaRadioGroupProps,
    slots: {
      label:
        "Ist der Zugang zum Schacht nur unterwiesenem Personal möglich bzw. finden sonstige Arbeiten wie die Wartung einer Schachtentrauchubgsanlage nur in Begleitung einer befugten Person statt?",
    },
  },
];

const offerStepG: KisenyaFormBuilderFormField[] = [
  {
    id: "g1",
    type: "radio_group",
    args: {
      required: true,
      direction: "horizontal",
      options: [
        {
          value: "ja",
          label: "ja",
        },
        {
          value: "nein",
          label: "nein",
        },
        {
          value: "überspringen",
          label: "überspringen",
        },
      ],
    } as KisenyaRadioGroupProps,
    slots: {
      label:
        "Gibt es einen bestehenden Notrufvertrag mit einer Aufschaltung zu einer ständig besetzten Stelle? (Zwei-Wege-Kommunikationssystem)",
    },
  },
  {
    id: "g2",
    type: "radio_group",
    args: {
      required: true,
      direction: "horizontal",
      options: [
        {
          value: "ja",
          label: "ja",
        },
        {
          value: "nein",
          label: "nein",
        },
        {
          value: "überspringen",
          label: "überspringen",
        },
      ],
    } as KisenyaRadioGroupProps,
    slots: {
      label:
        "Ist die Befreiung eingeschlossener Personen gewährleistet (Notbefreiungsdienst ist meist im Notrufvertrag enthalten)?",
    },
  },
];

const offerStepH: KisenyaFormBuilderFormField[] = [
  {
    id: "h1",
    type: "radio_group",
    args: {
      required: true,
      direction: "horizontal",
      options: [
        {
          value: "ja",
          label: "ja",
        },
        {
          value: "nein",
          label: "nein",
        },
        {
          value: "überspringen",
          label: "überspringen",
        },
      ],
    } as KisenyaRadioGroupProps,
    slots: {
      label:
        "Liegt ein Notfallplan vor? (In der Regel beim Notbefreiungsdienst und beim Betreiber)",
    },
  },
  {
    id: "h2",
    type: "radio_group",
    args: {
      required: true,
      direction: "horizontal",
      options: [
        {
          value: "ja",
          label: "ja",
        },
        {
          value: "nein",
          label: "nein",
        },
        {
          value: "überspringen",
          label: "überspringen",
        },
      ],
    } as KisenyaRadioGroupProps,
    slots: {
      label:
        "Ist der Notfallplan am Zugang zum Aufzug ausgehängt und für jeden ersichtlich?",
    },
  },
  {
    id: "h3",
    type: "uploader",
    args: {} as KisenyaUploaderProps,
    slots: {
      label: "Laden Sie den bestehenden Notfallplan hoch.",
    },
  },
];

const offerStepI: KisenyaFormBuilderFormField[] = [
  {
    id: "i1",
    type: "radio_group",
    args: {
      required: true,
      direction: "horizontal",
      options: [
        {
          value: "ja",
          label: "ja",
        },
        {
          value: "nein",
          label: "nein",
        },
        {
          value: "überspringen",
          label: "überspringen",
        },
      ],
    } as KisenyaRadioGroupProps,
    slots: {
      label:
        "Findet die jährliche ZÜS-Prüfung durch eine zugelassene Überwachungsstelle statt (TÜV, Dekra, GTÜ etc.)?",
    },
  },
  {
    id: "i2",
    type: "radio_group",
    args: {
      required: true,
      direction: "horizontal",
      options: [
        {
          value: "ja",
          label: "ja",
        },
        {
          value: "nein",
          label: "nein",
        },
        {
          value: "überspringen",
          label: "überspringen",
        },
      ],
    } as KisenyaRadioGroupProps,
    slots: {
      label: "Ist der Aufzug aktuell mängelfrei?",
    },
  },
  {
    id: "i3",
    type: "input",
    args: {
      type: "textarea",
      placeholder: "Beschreiben Sie in wenigen Worten",
    } as KisenyaInputProps,
    slots: {
      label: "Beschreiben Sie sonstige Informationen zum Aufzug.",
    },
  },
  {
    id: "i4",
    type: "uploader",
    args: {} as KisenyaUploaderProps,
    slots: {
      label: "Laden Sie den letzten Mängelbericht hoch.",
    },
  },
];

const offerStepK: KisenyaFormBuilderFormField[] = [
  {
    id: "k1",
    type: "radio_group",
    args: {
      required: true,
      direction: "horizontal",
      options: [
        {
          value: "ja",
          label: "ja",
        },
        {
          value: "nein",
          label: "nein",
        },
        {
          value: "überspringen",
          label: "überspringen",
        },
      ],
    } as KisenyaRadioGroupProps,
    slots: {
      label:
        "Wurde eine Bewertung im Bezug auf Cybersicherheit durchgeführt und protokolliert?",
    },
  },
];

const offerStepJ: KisenyaFormBuilderFormField[] = [
  {
    id: "j1",
    type: "input",
    args: {
      placeholder: "max@mustermann.de",
      required: true,
      rules: [
        (val: string) => emailRule.test(val) || "Please provide a valid email.",
      ],
    } as KisenyaInputProps,
    slots: {
      label: "Ihre E-Mail Adresse",
    },
  },
  {
    id: "j2",
    type: "input",
    args: {
      placeholder: "Max Mustermann",
    } as KisenyaInputProps,
    slots: {
      label: "Ihr Name",
    },
  },
  {
    id: "j3",
    type: "input",
    args: {
      placeholder: "zb. 015789012345",
    } as KisenyaInputProps,
    slots: {
      label: "Ihre Telefonnummer",
    },
  },
  {
    id: "j5",
    type: "checkbox",
    args: {
      required: true,
    } as KisenyaCheckboxProps,
    slots: {
      label:
        "Hiermit akzeptiere ich die von mir zur Kenntnis genommene Datenschutzerklärung. Sie können diese Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns.",
    },
  },
];
</script>
